<template>
  <b-sidebar
    id="stock-form-sidebar"
    :visible="isStockFormSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @shown="getData(StockData)"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-stock-form-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{headertext}}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >


          <validation-provider
            #default="validationContext"
            name="ยา/อุปกรณ์"
            rules="required"
          >
            <b-form-group
              :label="headertext.replace('เพิ่ม','เลือก')"
              label-for="product_id"
            >
            <div v-if="groupchoice==1">
              <v-select
                :options="Medicine_Data"
                v-model="Data.product_id"
                :get-option-label="(option) => option.title"
                :reduce="val => val.id"
                >
                <template #option="{ title,unit_name,sub_unit,num }">
                {{ title }}
                <br />
                <cite v-if="unit_name">{{num}} {{ sub_unit }} ต่อ 1 {{ unit_name }}</cite>
                </template>
              </v-select>
            </div>
            <div v-else-if="groupchoice==2">
              <v-select
              
                :options="Equipment_Data"
                v-model="Data.product_id"
                :get-option-label="(option) => option.title"
                :reduce="val => val.id"
                >
                <template #option="{ title,unit_name,sub_unit,num }">
                {{ title }}
                <br />
                <cite v-if="unit_name">{{num}} {{ sub_unit }} ต่อ 1 {{ unit_name }}</cite>
                </template>
              </v-select>
            </div>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="price"
            rules="required"
          >
            <b-form-group
              label="จำนวนที่ใช้"
              label-for="stock_out"
            >
              <b-form-input
                v-model="Data.stock_out"
                autofocus
                type="number"
                step="0.01"
                :state="getValidationState(validationContext)"
                trim
                placeholder="จำนวนที่ใช้"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

        
          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="status"
          >
            <b-form-group
              label="status"
              label-for="status"
            >
              <b-form-checkbox 
                switch
                v-model="Data.is_active"
                :state="getValidationState(validationContext)"
                trim name="check-button" 
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormCheckbox, BFormInput, BFormSelect, BFormInvalidFeedback, BButton,BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref,onUnmounted } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { slugify } from '@/@fake-db/utils'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useMeterialStockList from './useMeterialStockList'
import meterialStoreModule from '../meterialStoreModule'
import router from '@/router'
import { integer } from 'vee-validate/dist/rules'
export default {
  components: {
    BFormCheckbox,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormDatepicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isStockFormSidebarActive',
    event: 'update:is-stock-form-sidebar-active',
  },
  props: {
    isStockFormSidebarActive: {
      type: Boolean,
      required: true,
    },
    StockData: {
      type: Object,
      required: true,
    },
    headertext:{
      type:String,
      required: true,
    },
    groupchoice:{
      required:true
    }

  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
 
  setup(props, { emit }) {
    const id = ref(router.currentRoute.params.id);
    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const toast = useToast()
    const blankData = {
    
      branch_id:userStorage.branch_id,
      created_by: null,
      is_active:true
   
    }

    const Data = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      Data.value = JSON.parse(JSON.stringify(blankData))
    }
    const getData = (data)=>{
      if(data){ 
        Data.value  = JSON.parse(JSON.stringify(data))
      }
    }
    const PATIENTS_STORE_MODULE_NAME = 'app-stock';
        if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, meterialStoreModule)
        onUnmounted(() => {
            if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
        });
        const {
            Equipment_Data,
            Medicine_Data,
            fetchData,
            resolveBalanceVariant
        } = useMeterialStockList(id);
    const onSubmit = () => {
      //default 
      Data.value.meterial_id = parseInt(id.value);
      Data.value.branch_id = userStorage.branch_id;
      Data.value.created_by = userStorage.id;

      //
      if(Data.value.is_active == true){
        Data.value.is_active = 1;
      }
      else if(Data.value.is_active == false){
        Data.value.is_active = 0;
      }
      if(Data.value.stock_out){
        Data.value.stock_out = parseFloat(Data.value.stock_out);
      }

      console.log(Data.value.is_active);
        if(Data.value.id){
          console.log(Data.value);
          store.dispatch('app-stock/updateStockData', Data.value)
          .then(() => {
            emit('refetch-data')
            emit('update:is-stock-form-sidebar-active', false)
            toast({
            component: ToastificationContent,
            props: {
              title: "Save Success",
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          }).catch(()=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Error Save",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
    
        })

        }else{
          store.dispatch('app-stock/addStockData', Data.value)
          .then(() => {
            emit('refetch-data')
            emit('update:is-stock-form-sidebar-active', false)
            toast({
            component: ToastificationContent,
            props: {
              title: "Save Success",
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          }).catch(()=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Error Save",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
    
        })

        }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)


    return {
      fetchData,
      Equipment_Data,
      Medicine_Data,
      Data,
      onSubmit,
      resolveBalanceVariant,
      refFormObserver,
      getValidationState,
      resetForm,
      getData
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

#stock-form-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
