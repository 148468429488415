import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useMeterialList(product_id) {
  const id = product_id;
  const searchQuery = ref('');
  const selected_group = ref(0);
  const selected_category = ref(0);
  const selected_type = ref(0);
  const selected_status = ref(0);
  const refListTable = ref(null);
  const currentPage = ref(1);
  const pageLength = ref(10);
  const Total = ref(0);
  const Medicine_Data = ref([]);
  const Equipment_Data = ref([]);
  const dir = ref(false);
  const toast = useToast()
  const tableColumns = [

      { field: 'id', label: 'id', sortable: false, hidden:true },
        { field: 'title', label: 'ชื่อ', sortable: false },
        { field: 'group_name', label: 'กลุ่ม', sortable: false },
        { field: 'sell_price', label: 'ราคาขาย', sortable: false },
        { field: 'balance', label: 'คงเหลือ', sortable: false },
        
        { field: 'stock_out', label: 'จำนวนที่ใช้', sortable: false },
        { field: 'is_active', label: 'สถานะ', sortable: false },
        { field: 'action', label: 'การจัดการ', sortable: false },


    ]
    const Data = ref([]);
    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const branch_id = userStorage.branch_id;

    const refetchData = () => {
      refListTable.value.refresh()
  }
    watch([currentPage, pageLength, searchQuery,selected_group,selected_category,selected_type,selected_status,id], () => {
      fetchData();
      fetchMedicineData();
    })

    const fetchData = async () => {
        const offset = pageLength.value*(currentPage.value-1);
        Data.value  = [];
        await store
          .dispatch('app-stock/fetchStockData', {
            limit: pageLength.value,
            offset:offset,
            keywords: searchQuery.value,
            meterial_id:id.value,
            is_active:selected_status.value,
            branch_id:branch_id,
            order_by:'id DESC'
         
          })
          .then(response => {
          
            response.data.data.forEach((data) => data.balance = data.med_stock_in - data.med_stock_out);

            Data.value = response.data.data;

            console.log('meterial stock value',Data.value);
            const total = response.data.total?response.data.total: Data.value.length;
           
            Total.value = total
          })
          .catch((error) => {
            console.log('error',error);
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching stock' list",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
    }



  fetchData();
  
  const fetchMedicineData = async () => {

    await store
      .dispatch('app-stock/fetchMedicineData', {
        is_active:1,
       // branch_id:branch_id,
        order_by:'medicines.id DESC'
     
      })
      .then(response => {

        response.data.data.forEach((data) => {
             
          data.balance = data.stock_in - data.stock_out
          console.log('abc', data);
          if(data.group_types == 'ยา'){Medicine_Data.value.push(data)}
          else if(data.group_types == 'อุปกรณ์'){Equipment_Data.value.push(data)}
          console.log('Medicine_Data',data);
        });
      })
      .catch((error) => {
        console.log('error',error);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching medicines' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
}

fetchMedicineData();
  const deleteData = (id) => {
    //fetchDeleteProperty
    var data = {id:id}
    
    var res = store.dispatch('app-stock/deleteStockData',data)
    res.then(response=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Delete Success",
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
      fetchData();
    }).catch(()=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Error Delete",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })

    })
    //console.log('id', id);
    
  }
  const resolveUserStatusVariant = status => {
       
    if (status) return 'success'
    if (!status) return 'secondary'
    return 'primary'
  }
  const resolveUserStatusnameVariant = status => {
  
      if (status) return 'Active'
      if (!status) return 'Inactive'
      return 'primary'
  }
  const resolveMeterialVariant = status =>{
    if (status == 'วัสดุ') return 'dark'
    else if (status == 'บริการ') return 'warning'
    return 'light'
  }
  const resolveMeterialGroupnameVariant = status =>{
    if (status == 'วัสดุ') return 'ShieldIcon'
    else if (status == 'บริการ') return 'SmileIcon'
    return 'light'
  }
  const resolveMedicineVariant = status =>{
    if (status == 'ยา') return 'danger'
    else if (status == 'อุปกรณ์') return 'primary'
    return 'light'
  }
  const resolveMedicineGroupnameVariant = status =>{
    if (status == 'ยา') return 'HeartIcon'
    else if (status == 'อุปกรณ์') return 'SquareIcon'
    return 'light'
  }
  const resolveBalanceVariant = balance =>{
    if (balance == 0 || balance == null) return 'text-danger'
  }
    return {
        fetchData,
        fetchMedicineData,
        Medicine_Data,
        Equipment_Data,
        tableColumns,
        deleteData,
        Total,
        Data,
        pageLength,
        dir,
        searchQuery,
        selected_group,
        selected_category,
        selected_type,
        selected_status,
        id,
        currentPage,
        refListTable,
        resolveUserStatusVariant,
        resolveUserStatusnameVariant,
        resolveMeterialVariant,
        resolveMeterialGroupnameVariant,
        resolveBalanceVariant,
        resolveMedicineVariant,
        resolveMedicineGroupnameVariant
    }
}